const DTMap = [{
    key: 'urqo',
    name: "urqo",
    fullname: "Urqopacha",
    instances: 1,
    arank1: "Nechuciho",
    arank2: "Queen Hawk",
    aetherytes: [{
        name: "Wachunpelo",
        x: 27.8,
        y: 12.6
    }, {
        name: "Worlar's Echo",
        x: 30.5,
        y: 33.7
    }],
    aranks: [{
        x: 11.4,
        y: 8.7
    }, {
        x: 19.3,
        y: 13.9
    }, {
        x: 28.2,
        y: 22.7
    }, {
        x: 21.5,
        y: 20.9
    }, {
        x: 15.6,
        y: 23.6
    }, {
        x: 28.6,
        y: 9.4
    }, {
        x: 7.9,
        y: 25.1
    }]
}, {
    key: 'kozama',
    name: "kozama",
    instances: 1,
    fullname: "Kozama'uka",
    arank1: "The Raintriller",
    arank2: "Pkuucha",
    aetherytes: [{
        name: "Ok'hanu",
        x: 17.8,
        y: 11.4
    }, {
        name: "Earthenshire",
        x: 11.6,
        y: 27.2
    }, {
        name: "Many Fires",
        x: 31.9,
        y: 25
    }, {
        name: "Dock Poga",
        x: 37.2,
        y: 16.7
    }],
    aranks: [{
        x: 16,
        y: 17
    }, {
        x: 5.8,
        y: 11.7
    }, {

        x: 23.9,
        y: 36.6
    }, {
        x: 4.9,
        y: 28.9
    }, {
        x: 36.8,
        y: 20.4
    }, {
        x: 15.7,
        y: 23.4
    }, {
        x: 19.7,
        y: 27.8
    }, {
        x: 8.4,
        y: 7
    }]

}, {
    key: 'yak',
    name: "yak",
    instances: 1,
    fullname: "Yak T'el",
    arank1: "Starcrier",
    arank2: "Rrax Yity'a",
    aetherytes: [{
        name: "Iq Br'aax",
        x: 13.2,
        y: 12.3
    }, {
        name: "Mamook",
        x: 35.7,
        y: 31.6
    }],
    aranks: [{
        x: 26.2,
        y: 9.4
    }, {
        x: 23.3,
        y: 13.7
    }, {
        x: 8.5,
        y: 19.4
    }, {
        x: 33,
        y: 16
    }, {
        x: 24.7,
        y: 33.1
    }, {
        x: 21.7,
        y: 28
    }, {
        x: 21.3,
        y: 36.4
    }]
}, {
    key: 'shaa',
    name: "shaa",
    fullname: "Shaaloani",
    instances: 1,
    arank1: "Yehehetoaua'pyo",
    arank2: "Keheniheyamewi",
    aetherytes: [{
        name: "Mehwahhetsoan",
        x: 27.3,
        y: 9.6
    }, {
        name: "Sheshenewezi Springs",
        x: 15.3,
        y: 19
    }, {
        name: "Hhusatahwi",
        x: 28.8,
        y: 30.8
    }],
    aranks: [{
        x: 21.7,
        y: 32.8
    }, {
        x: 22.2,
        y: 18.3
    }, {
        x: 22,
        y: 26.5
    }, {
        x: 32.3,
        y: 22.5
    }, {
        x: 35,
        y: 6
    }, {
        x: 24.6,
        y: 23.1
    }, {
        x: 9,
        y: 16.3
    }, {
        x: 16,
        y: 8.2
    }]
}, {
    key: 'heritage',
    name: "heritage",
    fullname: "Heritage Found",
    arank1: "Heshuala",
    arank2: "Urna Variabilis",
    aetherytes: [{
        name: "The Outskirts",
        x: 16.7,
        y: 9.3
    }, {
        name: "Electrope Strike",
        x: 16.8,
        y: 23.3
    }, {
        name: "Yyasulani Station",
        x: 31.5,
        y: 25
    }],
    instances: 1,
    aranks: [{
        x: 30.3,
        y: 20.3
    }, {
        x: 24.0,
        y: 20.2
    }, {
        x: 27,
        y: 13.5
    }, {
        x: 14.2,
        y: 25.7
    }, {
        x: 30.3,
        y: 29.2
    }, {
        x: 27,
        y: 33.9
    }, {
        x: 12.8,
        y: 21.4
    }, {
        x: 8.5,
        y: 33.0
    }]
}, {
    key: 'memory',
    name: "memory",
    arank1: "Sally The Sweeper",
    arank2: "Cat's Eye",
    instances: 1,
    fullname: "Living Memory",
    othername: "Living Memory",
    aetherytes: [{
        name: "Leynode Mnemo",
        x: 21.4,
        y: 37.2
    }, {
        name: "Leynode Pyro",
        x: 34.6,
        y: 15.4
    }, {
        name: "Leynode Aero",
        x: 16.3,
        y: 12.9
    }],
    aranks: [{
        x: 5.1,
        y: 12.5
    }, {
        x: 4.3,
        y: 29
    }, {
        x: 18.8,
        y: 20.2
    }, {
        x: 12.9,
        y: 27.4
    }, {
        x: 12.4,
        y: 37.8
    }, {
        x: 26.8,
        y: 31
    }]
}]

exports.DTMap = DTMap
